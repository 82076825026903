









import {
  defineComponent,
  ref,
  computed,
  onMounted,
  useContext,
  useRoute,
} from '@nuxtjs/composition-api';
import { usePageStore } from '~/stores/page';

import CATEGORY from '~/modules/catalog/pages/category.vue';
import PRODUCT from '~/modules/catalog/pages/product.vue';

import { RoutableInterface } from '~/modules/GraphQL/types';
import { clearUrl } from '~/utilities/clearUrl';

export default defineComponent({
  name: 'PageResolver',
  components: {
    CATEGORY,
    PRODUCT,
    CMS_PAGE: () => import('~/pages/Cms.vue'),
    PRODUCT_RECOMMENDATION_QUIZ: () => import('~/modules/quiz/pages/Quiz.vue'),
    BLOG_POSTS: () => import('~/modules/blog/pages/Blog.vue'),
    BLOG_POST: () => import('~/modules/blog/pages/Post.vue'),
    PROMOTIONS: () => import('~/modules/promo/pages/promos.vue'),
    PROMOTION: () => import('~/modules/promo/pages/promo.vue'),
    BOUTIQUE_LANDINGS: () => import('~/modules/boutiques/pages/Boutiques.vue'),
    BOUTIQUE_LANDING: () => import('~/modules/boutiques/pages/Boutique.vue'),
    CATEGORY_COLLECTION_TYPE: () => import('~/modules/collections/pages/collections.vue'),
    CATEGORY_COLLECTION: () => import('~/modules/collections/pages/collection.vue'),
  },
  middleware: [
    'url-resolver',
  ],
  setup() {
    const pageStore = usePageStore();
    const { app } = useContext();
    const route = useRoute();

    const type = computed(() => pageStore.routeData?.type);
    const backupType = ref(null);

    onMounted(async () => {
      if (type.value === undefined) {
        const { data, errors } = await app.$vsf.$magento.api.route(clearUrl(route.value.path, app));
        const results: RoutableInterface | null = data?.route ?? null;

        if (!results || errors?.length) {
          app.error({ statusCode: 404 });
        } else {
          backupType.value = results?.type;
          pageStore.$patch((state) => {
            state.routeData = results;
          });
        }
      }
    });

    return {
      type,
      backupType
    };
  },
});
